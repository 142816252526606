/* eslint-disable no-restricted-imports */
import {
  Formats,
  TranslationValues,
  useTranslations as useTranslationsNextIntl,
} from 'next-intl';

export type TFunction = (
  key: string,
  options?:
    | string
    | {
        fallback?: string;
        formats?: Partial<Formats>;
        values?: TranslationValues;
      },
) => string;

export const useTranslations = (): TFunction => {
  const t = useTranslationsNextIntl();

  return (key, options = {}) => {
    const {
      fallback,
      formats = undefined,
      values = undefined,
    } = typeof options === 'string' ? { fallback: options } : options;

    if (t.has(key)) return t(key, values, formats);

    return t(fallback, values, formats) ?? key;
  };
};
