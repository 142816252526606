'use client';

import { ErrorPage as ErrorComponent } from '@grimme/components';
import { useRouter } from '@/lib/i18n/routing';
import { useTranslations } from '@/lib/i18n/use-translations';

// copied from src/pages/error/ErrorPage.tsx
// TODO: either create a new Error Page component in this project or in UI library as section to and get rid of client side dependencies

export function ErrorPage() {
  const t = useTranslations();
  const router = useRouter();

  const buttonText = t('myGRIMME_core_home', 'Startseite');
  const paragraph = t(
    'myGRIMME_core_404_message',
    'Die Seite, nach der du suchst, konnte leider nicht gefunden werden.',
  );
  const title = t('myGRIMME_core_404_heading', 'Falsche Abfahrt?');

  const handleClick = () => router.push(`/`);

  return (
    <ErrorComponent
      confirmButtonText={buttonText}
      paragraph={paragraph}
      title={title}
      onConfirm={handleClick}
    />
  );
}
